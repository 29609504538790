import React, { useEffect, useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Card, Col, Form, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "react-select";
import moment from "moment";
import withLoader from "../../layout/Loader/WithLoader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import jsPDF from "jspdf";
import "jspdf-autotable";
import NeosLogo from "../../../assets/img/neos-logo.jpg";
import * as ExcelJS from "exceljs";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useDialogState from "../../hooks/useDialog";
import AddDisputeRequest, { ConfirmRequest } from "./AddDisputeRequest";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import PaymentService from "../../services/payment.service";
import {
  CUSTOMDROPDOWNSTYLE,
  PAGELIMIT,
  ReplaceFunction,
} from "../../utils/constants";
import Enums from "../../utils/enums";
import TransactionService from "../../services/transaction.service";

const UserRechargesByDealer = () => {
  const { USER_RECHARGES_STATUS, RANGES } = Enums();
  const paymentService = PaymentService();
  const transactionService = TransactionService();
  const { t } = useTranslation("common");
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [dataPerPage, setDataPerPage] = useState(PAGELIMIT);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [date, setDate] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const {
    open: isDialogOpen,
    handleClickOpen: handleDialogOpen,
    handleClose: handleDialogClose,
  } = useDialogState();

  const setDates = (e, { startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const closeDialog = () => {
    handleDialogClose();
    formik.resetForm();
  };

  const handlesearch = (e) => {
    setSearchText(e.target.value);
  };

  const getDealerToUserRefills = async () => {
    const response = await transactionService.getUserRefillsAll({
      endDate: new Date(endDate).getTime(),
      needDealerList: false,
      pageLimit: dataPerPage,
      pageNumber: pageNumber,
      searchString: searchText,
      startDate: new Date(startDate).getTime(),
      status: selectedStatus,
      userId: 0,
    });
    setData(response.data.apiresponse.data.recharges);
    setTotalRows(response.data.apiresponse.data.totalRecords);
  };

  useEffect(() => {
    getDealerToUserRefills();
  }, []);

  useEffect(() => {
    getDealerToUserRefills();
  }, [pageNumber, startDate, endDate, dataPerPage, searchText, selectedStatus]);

  const handleStatusByAdmin = async (id, amount) => {
    handleDialogOpen();
    const response = await transactionService.paymentDetails(id);
    const result = response.data.apiresponse.data;

    setDate(result.creationDate);
    setAmount(amount);
    formik.setFieldValue("id", id);
    formik.setFieldValue("dealername", result.dealername);
    formik.setFieldValue("username", result.username);
    formik.setFieldValue("dealerWalletAmount", result.dealerWalletAmount);
    formik.setFieldValue("userWalletAmount", result.userWalletAmount);
  };

  const formik = useFormik({
    initialValues: {
      dealername: "",
      dealerWalletAmount: "",
      username: "",
      userWalletAmount: "",
      dealerAmount: 0,
      userAmount: 0,
      dealerAfterPayment: "",
      userAfterPayment: "",
    },
    validationSchema: yup.object().shape({
      dealername: yup.string().required("Please enter Dealer Name!"),
      dealerWalletAmount: yup.number().required("Please enter Wallet Amount!"),
      username: yup.string().required("Please enter User Name!"),
      userWalletAmount: yup.number().required("Please enter Wallet Amount!"),
      userAmount: yup.number().required("Please enter User Amount!"),
      dealerAmount: yup.number().required("Please enter Dealer Amount!"),
    }),
    onSubmit: async (values, action) => {
      const { dealerAmount, userAmount, id } = values;
      // const user =
      const response = await transactionService.appproveDisputeRequest(
        dealerAmount,
        id,
        userAmount
      );

      action.resetForm();
      enqueueSnackbar("Wallet Amount Updated Successfully!", {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom",
        },
      });
      getDealerToUserRefills();
      setShowModal(false);
      handleClose();
    },
  });

  const handleOpen = () => {
    setShowModal(true);
    handleDialogClose();
  };

  const handleClose = () => {};

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const columns = [
    {
      name: <strong className="tx-uppercase">{t("uniqueid")}</strong>,
      selector: "uuniqueId",
      cell: (row) => (
        <div title={`${row.uuniqueId ? row.uuniqueId : "--"}`}>
          {row.uuniqueId ? row.uuniqueId : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("username")}</strong>,
      selector: "uusername",
      cell: (row) => (
        <div title={`${row.uusername ? row.uusername : row.uemail}`}>
          {row.uusername ? row.uusername : row.uemail}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("surname")}</strong>,
      selector: "usurname",
      cell: (row) => (
        <div title={`${row.usurname ? row.usurname : "--"}`}>
          {row.usurname ? row.usurname : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">{t("importbeforerecharge")}</strong>
      ),
      selector: "beforeAmount",
      cell: (row) => (
        <div
          title={`${
            row.beforeAmount
              ? ReplaceFunction(row.beforeAmount?.toFixed(2))
              : "--"
          }`}
        >
          {row.beforeAmount
            ? ReplaceFunction(row.beforeAmount?.toFixed(2))
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("importrecharge")}</strong>,
      selector: "amount",
      cell: (row) => (
        <div
          title={`${
            row.amount ? ReplaceFunction(row.amount?.toFixed(2)) : "--"
          }`}
        >
          {row.amount ? ReplaceFunction(row.amount?.toFixed(2)) : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">{t("importafterrecharge")}</strong>
      ),
      selector: "afterAmount",
      cell: (row) => (
        <div
          title={`${
            row.afterAmount
              ? ReplaceFunction(row.afterAmount?.toFixed(2))
              : "--"
          }`}
        >
          {row.afterAmount
            ? ReplaceFunction(row.afterAmount?.toFixed(2))
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("dealercompanyname")}</strong>,
      selector: "dcompanyname",
      cell: (row) => (
        <div title={`${row.dcompanyname ? row.dcompanyname : "--"}`}>
          {row.dcompanyname ? row.dcompanyname : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("town")}</strong>,
      selector: "town",
      cell: (row) => (
        <div title={`${row.town ? `${row.town}` : "--"}`}>
          {row.town ? `${row.town}` : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("type")}</strong>,
      selector: "type",
      cell: (row) => (
        <div
          title={`${
            row.type
              ? row.type === "MANUAL"
                ? t("manual")
                : row.type === "MANUAL_BY_ADMIN"
                ? t("manualbyadmin")
                : row.type === "RECHARGE"
                ? t("rechargecap")
                : row.type
              : "--"
          }`}
        >
          {row.type
            ? row.type === "MANUAL"
              ? t("manual")
              : row.type === "MANUAL_BY_ADMIN"
              ? t("manualbyadmin")
              : row.type === "RECHARGE"
              ? t("rechargecap")
              : row.type
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("apptype")}</strong>,
      selector: "appType",
      cell: (row) => (
        <div title={`${row.appType ? row.appType : "--"}`}>
          {row.appType ? row.appType : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("description")}</strong>,
      selector: "reasonForCancel",
      cell: (row) => (
        <div title={`${row.reasonForCancel ? row.reasonForCancel : "--"}`}>
          {row.reasonForCancel ? row.reasonForCancel : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("paydate")}</strong>,
      selector: "creationDate",
      cell: (row) => (
        <div
          title={`${
            row.creationDate
              ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
              : "--"
          }`}
        >
          {row.creationDate
            ? moment(row.creationDate).format("DD/MM/YYYY HH:mm:ss")
            : "--"}
        </div>
      ),
      sortable: true,
    },
    {
      name: (
        <strong className="tx-uppercase">
          {t("status") + " " + t("by") + " " + t("admin")}
        </strong>
      ),
      selector: "approvalStatus",
      cell: (row) => (
        <div className="d-flex">
          {row.status === "DISPUTE" ? (
            <>
              <div className="">
                {row.approvalStatus === "PENDING" && (
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={() =>
                      handleStatusByAdmin(row.transactionId, row.amount)
                    }
                    style={{
                      width: "95px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-clock"></i> {t("pending")}
                  </button>
                )}
              </div>
              <div className="">
                {row.approvalStatus === "APPROVED" && (
                  <Link
                    className="btn btn-success btn-sm"
                    style={{
                      width: "95px",
                      textAlign: "center",
                      cursor: "default",
                    }}
                  >
                    <i className="fa fa-check"></i> {t("approved")}
                  </Link>
                )}
              </div>
            </>
          ) : (
            <div>--</div>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: <strong className="tx-uppercase">{t("status")}</strong>,
      selector: "status",
      cell: (row) => (
        <div className="d-flex">
          <div className="">
            {row.status === "IN_PROGRESS" && (
              <div className="text-warning">
                <i class="fa fa-hourglass"></i> {t("inprogress")}
              </div>
            )}
          </div>
          <div className="">
            {row.status === "COMPLETED" && (
              <div className="text-success">
                <i class="fa fa-check"></i> {t("completed")}
              </div>
            )}
          </div>
          <div className="">
            {row.status === "CANCEL" && (
              <div className="text-danger">
                <i class="si si-close"></i> {t("canceled")}
              </div>
            )}
          </div>
          <div className="">
            {row.status === "DISPUTE" && (
              <div className="text-info">
                <i class="fa fa-ban"></i> {t("disputed")}
              </div>
            )}
          </div>
          <div className="">
            {row.status === "REFUND" && (
              <div className="text-info">
                <i class="fa fa-ban"></i> {t("refund")}
              </div>
            )}
          </div>
        </div>
      ),
      sortable: true,
    },
  ];

  let totalAmount = 0;
  let totalBeforeAmount = 0;
  let totalAfterAmount = 0;
  for (let i = 0; i < data?.length; i++) {
    totalAmount += parseFloat(data[i]?.amount);
    totalBeforeAmount += parseFloat(data[i]?.beforeAmount);
    totalAfterAmount += parseFloat(data[i]?.afterAmount);
  }

  const handleDealerAmountChange = (e) => {
    const inputValue = e.target.value;
    formik.setFieldValue("dealerAmount", inputValue);
    if (inputValue[0] === "-") {
      const newData =
        formik.values.dealerWalletAmount - parseFloat(inputValue.slice(1));
      formik.setFieldValue("dealerAfterPayment", newData);
    } else {
      const newData = formik.values.dealerWalletAmount + parseFloat(inputValue);
      formik.setFieldValue("dealerAfterPayment", newData);
    }
  };

  const handleUserAmountChange = (e) => {
    const inputValue = e.target.value;
    formik.setFieldValue("userAmount", inputValue);

    if (inputValue[0] === "-") {
      const newData =
        formik.values.userWalletAmount - parseFloat(inputValue.slice(1));
      formik.setFieldValue("userAfterPayment", newData);
    } else {
      const newData = formik.values.userWalletAmount + parseFloat(inputValue);
      formik.setFieldValue("userAfterPayment", newData);
    }
  };

  const totalRecharge = data.length;

  const exportPDF = () => {
    const unit = "pt";
    const size = "A3"; // Use A1, A2, A3, or A4
    const orientation = "landscape"; // portrait or landscape
    const titleFontSize = 20;
    const textColor = [0, 0, 0];

    const doc = new jsPDF(orientation, unit, size);

    const imgData = NeosLogo;
    doc.addImage(imgData, "JPEG", 950, 10, 180, 80);

    const title = "Ricariche NeosApp";
    doc.setFontSize(titleFontSize);
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.setFont("helvetica", "bold");
    doc.text(title, 40, 35);

    const period = startDate ? "Periodo dal" : "Periodo";
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(period, 40, 100); // Adjust the vertical position as needed

    const startdate = startDate
      ? moment(startDate).format("DD/MM/YYYY")
      : "Tutto";
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(startdate, 170, 100); // Adjust the vertical position as needed

    const to = startDate ? "al" : "";
    doc.setFont("helvetica", "normal");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(to, 280, 100); // Adjust the vertical position as needed

    const enddate = endDate ? moment(endDate).format("DD/MM/YYYY") : "";
    doc.setFont("helvetica", "bold");
    doc.setDrawColor(...textColor); // Border color (black)
    doc.setTextColor(...textColor); // Text color (black)
    doc.text(enddate, 320, 100); // Adjust the vertical position as needed

    const headers = [
      [
        "ID UNICO",
        "IMPORTO PRIMA DELLA RICARICA",
        "IMPORTO RICARICA",
        "IMPORTO DOPO LA RICARICA",
        "NOME DEL RIVENDITORE",
        "CITTA' DEL RIVENDITORE",
        "TIPO DI RICARICA",
        "DESCRIZIONE",
        "ORA DATI",
        "STATO",
      ],
    ];

    // set data table columns names
    const newData = data.map((elt) => [
      elt.uuniqueId,
      ReplaceFunction(elt.beforeAmount?.toFixed(2)),
      ReplaceFunction(elt.amount?.toFixed(2)),
      ReplaceFunction(elt.afterAmount?.toFixed(2)),
      {
        content: elt.dusername,
        colSpan: 1,
        styles: {
          halign: "center",
        },
      },
      elt.town,
      elt.type,
      elt.reasonForCancel ? elt.reasonForCancel : "--",
      moment(elt.creationDate).format("DD/MM/YYYY HH:mm:ss"),
      elt.status,
    ]);

    newData.push([
      {
        content: "Importo Totale",
        colSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          lineWidth: { top: 0.5, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [...textColor], // Border color (black)
        },
      },
      {
        content: "" + "",
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          lineWidth: { top: 0.5, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [...textColor], // Border color (black)
        },
      },
      {
        content: "€ " + ReplaceFunction(totalAmount?.toFixed(2)),
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          lineWidth: { top: 0.5, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [...textColor], // Border color (black)
        },
      },
      {
        content: " " + "",
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          lineWidth: { top: 0.5, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [...textColor], // Border color (black)
        },
      },
      {
        content: "",
        colSpan: 6,
        styles: {
          halign: "center",
          fontStyle: "bold",
          lineWidth: { top: 0.5, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [...textColor], // Border color (black)
        },
      },
    ]);

    newData.push([
      {
        content: "Numero di Ricariche",
        colSpan: 1,
        styles: {
          halign: "left",
          fontStyle: "bold",
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [...textColor], // Border color (black)
        },
      },
      {
        content: totalRecharge,
        colSpan: 1,
        styles: {
          halign: "center",
          fontStyle: "bold",
          lineWidth: { top: 0, right: 0, bottom: 0, left: 0 }, // Border width
          lineColor: [...textColor], // Border color (black)
        },
      },
    ]);

    const headStyles = {
      fillColor: "#ffffff",
      textColor: [...textColor],
      halign: "center",
      fontStyle: "bold",
      fontSize: 13,
      lineWidth: { top: 0, right: 0, bottom: 1.5, left: 0 },
      lineColor: [...textColor],
    };

    const bodyStyles = {
      fillColor: "#ffffff",
      halign: "center",
      textColor: [...textColor],
      cellPadding: { left: 5, right: 5, top: 7, bottom: 7 },
      fontSize: 13,
    };

    const columnStyles = {
      4: { halign: "right" }, // Align the content to the right for the last column
    };

    const alternateRowStyles = {
      fillColor: "#ffffff", // Background color for alternating rows
      textColor: [...textColor], // Text color for alternating rows
    };

    if (typeof doc.autoTable === "function") {
      doc.autoTable({
        startY: 150,
        head: headers,
        body: newData,
        headStyles: headStyles,
        bodyStyles: bodyStyles,
        alternateRowStyles: alternateRowStyles,
        columnStyles: columnStyles,
      });
    } else {
      console.error("jspdf-autotable plugin not loaded properly.");
    }

    doc.save("User_Recharges_By_Dealer.pdf");
  };

  const handleDownloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const commonCellStyle = {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "CCCCCC" },
      },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
      font: { color: { argb: "000000" }, bold: true },
    };

    const titleRow = worksheet.addRow(["Ricariche NeosApp"]);
    worksheet.mergeCells("A1:J1");

    titleRow.getCell(1).font = { ...commonCellStyle.font, size: 12 };
    worksheet.addRow([]);
    worksheet.mergeCells("A2:J2");

    const period = worksheet.getCell("A3");
    period.value = {
      richText: [
        {
          text: startDate ? "Periodo dal" : "Periodo",
          font: { name: "Helvetica", color: { argb: "000000" }, bold: false },
        },
        {
          text: startDate
            ? `       ${moment(startDate).format("DD/MM/YYYY")}  al  ${moment(
                endDate
              ).format("DD/MM/YYYY")}`
            : "       Tutto",
          font: { name: "Helvetica", color: { argb: "000000" }, bold: true },
        },
      ],
    };
    worksheet.mergeCells("A3:J3");

    worksheet.mergeCells("A4:J4");
    worksheet.mergeCells("A5:J5");

    // Add column headers
    const headers = [
      "ID UNICO",
      "IMPORTO PRIMA DELLA RICARICA",
      "IMPORTO RICARICA",
      "IMPORTO DOPO LA RICARICA",
      "NOME DEL RIVENDITORE",
      "CITTA' DEL RIVENDITORE",
      "TIPO DI RICARICA",
      "DESCRIZIONE",
      "ORA DATI",
      "STATO",
    ];
    worksheet.getColumn(1).width = 20; // Column N
    worksheet.getColumn(2).width = 30; // Column Card
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 25;
    worksheet.getColumn(5).width = 22;
    worksheet.getColumn(6).width = 25; // Column N
    worksheet.getColumn(7).width = 25; // Column Card
    worksheet.getColumn(8).width = 30;
    worksheet.getColumn(9).width = 25;
    worksheet.getColumn(10).width = 20;

    const headerRow = worksheet.addRow(headers);
    headerRow.getCell(1).alignment = { horizontal: "center" };
    headerRow.getCell(2).alignment = { horizontal: "center" };
    headerRow.getCell(3).alignment = { horizontal: "center" };
    headerRow.getCell(4).alignment = { horizontal: "center" };
    headerRow.getCell(5).alignment = { horizontal: "center" };
    headerRow.getCell(6).alignment = { horizontal: "center" };
    headerRow.getCell(7).alignment = { horizontal: "center" };
    headerRow.getCell(8).alignment = { horizontal: "center" };
    headerRow.getCell(9).alignment = { horizontal: "center" };
    headerRow.getCell(10).alignment = { horizontal: "center" };

    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: "none" },
        left: { style: "none" },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "none" },
      };
    });

    // Add data rows
    data.forEach((res) => {
      const table = worksheet.addRow([
        res.uuniqueId,
        ReplaceFunction(res.beforeAmount?.toFixed(2)),
        ReplaceFunction(res.amount?.toFixed(2)),
        ReplaceFunction(res.afterAmount?.toFixed(2)),
        res.dusername,
        res.town,
        res.type,
        res.reasonForCancel ? res.reasonForCancel : "--",
        moment(res.creationDate).format("DD/MM/YYYY HH:mm:ss"),
        res.status,
      ]);
      table.getCell(1).alignment = { horizontal: "center" };
      table.getCell(2).alignment = { horizontal: "center" };
      table.getCell(3).alignment = { horizontal: "center" };
      table.getCell(4).alignment = { horizontal: "center" };
      table.getCell(5).alignment = { horizontal: "center" };
      table.getCell(6).alignment = { horizontal: "center" };
      table.getCell(7).alignment = { horizontal: "center" };
      table.getCell(8).alignment = { horizontal: "center" };
      table.getCell(9).alignment = { horizontal: "center" };
      table.getCell(10).alignment = { horizontal: "center" };
      table.eachCell((cell) => {
        cell.border = {
          top: { style: "none" },
          left: { style: "none" },
          bottom: { style: "none" },
          right: { style: "none" },
        };
      });
    });

    const totalRicaricheRow = worksheet.addRow([
      "Importo Totale",
      "",
      "€ " + ReplaceFunction(totalAmount?.toFixed(2)),
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ]);

    totalRicaricheRow.getCell(1).alignment = { horizontal: "center" };
    totalRicaricheRow.getCell(2).alignment = { horizontal: "center" };
    totalRicaricheRow.getCell(3).alignment = { horizontal: "center" };
    totalRicaricheRow.getCell(4).alignment = { horizontal: "center" };

    totalRicaricheRow.eachCell((cell) => {
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "none" },
        bottom: { style: "none" },
        right: { style: "none" },
      };
      cell.font = commonCellStyle.font;
    });

    worksheet.addRow([]);
    worksheet.addRow([]);

    const totalNumberOfRecharge = worksheet.addRow([
      "Numero di Ricariche",
      totalRecharge,
    ]);

    totalNumberOfRecharge.getCell(1).alignment = { horizontal: "center" };
    totalNumberOfRecharge.getCell(2).alignment = { horizontal: "center" };
    totalNumberOfRecharge.eachCell((cell) => {
      cell.border = {
        // top: { style: "thin", color: { argb: "000000" } },
        left: { style: "none" },
        bottom: { style: "none" },
        right: { style: "none" },
      };
      cell.font = commonCellStyle.font;
    });

    // Create an Excel file
    const buffer = await workbook.xlsx.writeBuffer();

    // Download the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "User_Recharges_By_Dealer.xlsx";
    link.click();
  };

  const cancelDate = () => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleDateChange = () => {
    setStartDate(0);
    setEndDate(0);
  };

  const CUSTOM_STYLES = {
    headCells: {
      style: {
        "&:nth-last-child(1)": {
          position: "sticky",
          right: 0,
          backgroundColor: "#ffffff", // Set the background color for the last column
        },
        "&:nth-last-child(2)": {
          position: "sticky",
          right: "135px",
          backgroundColor: "#ffffff", // Set the background color for the last column
        },
      },
    },
    cells: {
      style: {
        "&:nth-last-child(1)": {
          position: "sticky",
          right: 0,
        },
        "&:nth-last-child(2)": {
          position: "sticky",
          right: "135px",
        },
      },
    },
    rows: {
      style: {
        ":hover": {
          backgroundColor: "#EEEEEE !important",
        },
        "&:nth-child(odd)": {
          backgroundColor: "#fafafa", // Set the background color for even rows
        },
        "&:nth-child(even)": {
          backgroundColor: "#FFFFFF", // Set the background color for odd rows
        },
        "&:nth-child(even) > :last-child": {
          backgroundColor: "#FFFFFF", // Set the background color for even cells in the last column
        },
        "&:nth-child(odd) > :last-child": {
          backgroundColor: "#fafafa", // Set the background color for odd cells in the last column
        },

        "&:nth-child(even) > :nth-last-child(2)": {
          backgroundColor: "#FFFFFF", // Set the background color for even cells in the last column
        },
        "&:nth-child(odd) > :nth-last-child(2)": {
          backgroundColor: "#fafafa", // Set the background color for odd cells in the last column
        },

        "&:nth-child(even):hover": {
          "& > :last-child, & > :nth-last-child(2)": {
            backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on even rows
          },
        },
        "&:nth-child(odd):hover": {
          "& > :last-child, & > :nth-last-child(2)": {
            backgroundColor: "#EEEEEE !important", // Set the background color for the last column when hovering on odd rows
          },
        },
      },
    },
  };

  return (
    <div className="page-header">
      <h2 className="main-content-title tx-24 mb-3 mt-2">
        {t("userrecharges")}
      </h2>
      {isDialogOpen && (
        <AddDisputeRequest
          show={isDialogOpen}
          onHide={closeDialog}
          formik={formik}
          handleOpen={handleOpen}
          showModal={showModal}
          handleDealerAmountChange={handleDealerAmountChange}
          handleUserAmountChange={handleUserAmountChange}
          amount={amount}
          date={date}
        />
      )}

      {showModal && (
        <ConfirmRequest
          show={showModal}
          onHide={() => setShowModal(false)}
          handleClose={handleClose}
          formik={formik}
        />
      )}

      <Card>
        <div className="p-3">
          <Row>
            <Col md={2}>
              <Form.Group className="form-group">
                <Multiselect
                  classNamePrefix="Select2"
                  options={USER_RECHARGES_STATUS}
                  singleSelect
                  placeholder={t("status")}
                  displayValue="key"
                  name="area"
                  onChange={(selectedOption) =>
                    setSelectedStatus(selectedOption.value)
                  }
                  styles={CUSTOMDROPDOWNSTYLE}
                />
              </Form.Group>
            </Col>
            <Col md={2}>
              <Form.Group className="form-group">
                {/* <DateRangePicker
                  initialSettings={{
                    startDate: moment(new Date())
                      .subtract(1, "months")
                      .format("MM/DD/YYYY"),
                    endDate: moment(new Date()).format("MM/DD/YYYY"),
                    ranges: RANGES,
                  }}
                  onApply={setDates}
                >
                  <input
                    type="text"
                    value={
                      moment(startDate).format("DD/MM/YYYY") +
                      " - " +
                      moment(endDate).format("DD/MM/YYYY")
                    }
                    className="form-control"
                  />
                </DateRangePicker> */}
                <DateRangePicker
                  initialSettings={{
                    startDate: moment(new Date()).format("DD/MM/YYYY"),
                    endDate: moment(new Date()).format("DD/MM/YYYY"),
                    ranges: RANGES,
                    autoUpdateInput: false,
                    locale: {
                      format: "DD/MM/YYYY", // Specify the desired format
                    },
                  }}
                  onApply={setDates}
                  onCancel={cancelDate}
                  onHide={cancelDate}
                >
                  {startDate && endDate ? (
                    <input
                      type="text"
                      placeholder={t("selectperiodvalidity")}
                      value={
                        startDate && endDate
                          ? `${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} - ${moment(endDate).format("DD/MM/YYYY")}`
                          : ""
                      }
                      defaultValue=""
                      onChange={(startDate, endDate) =>
                        handleDateChange(startDate, endDate)
                      }
                      className="form-control"
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder={t("selectperiodvalidity")}
                      defaultValue=""
                      className="form-control"
                    />
                  )}
                </DateRangePicker>
              </Form.Group>
            </Col>
            <Col md={8}>
              <div className="d-flex d-lg-flex add-search ms-auto">
                <div className="d-flex flex-grow-1 flex-md-grow-0 flex-lg-grow-0 align-items-center md-form mt-2 mt-md-auto mt-lg-auto ms-auto me-3">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control form-control-md py-3"
                      value={searchText}
                      onChange={handlesearch}
                      placeholder={t("search") + "..."}
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-primary btn-sm ms-auto mt-2 me-2 p-2"
                    onClick={handleDownloadExcel}
                  >
                    <i className="fas fa-upload"></i> {t("excel")}
                  </button>

                  <button
                    onClick={exportPDF}
                    className="btn btn-primary btn-sm mt-2 p-2"
                  >
                    <i class="fas fa-file-pdf"></i> PDF
                  </button>
                </div>{" "}
              </div>
            </Col>
          </Row>
          <DataTable
            title="User Recharge By Dealer"
            columns={columns}
            data={data}
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={dataPerPage}
            onChangeRowsPerPage={(page) => setDataPerPage(page)}
            onChangePage={handlePageChange}
            highlightOnHover
            customStyles={CUSTOM_STYLES}
          />
        </div>
      </Card>
    </div>
  );
};

export default withLoader(UserRechargesByDealer);
