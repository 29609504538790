import React, { Suspense } from "react";
import { Navigate, useRoutes } from "react-router";
import { lazy } from "react";
import ParkHistory from "../components/ParkHistory/ParkHistory";
import Invoices from "../components/Invoices/Invoices";
import Profile from "../components/Users/Profile";
import BusinessPartnersProfile from "../components/BusinessPartners/BusinessPartnerProfile";
import ViewParkingSlots from "../components/Parking/ViewParkingSlots";
import CityProfile from "../components/City/CityProfile";
import AreaProfile from "../components/Area/AreaProfile";
import RechargeRequest from "../components/PaidDealer/RechargeRequest";
import UserRequest from "../components/PaidDealer/UserRequest";
import PaidDealerInvoices from "../components/PaidDealer/PaidDealerInvoices";
import PaidDealerDashboard from "../components/PaidDealer/PaidDealerDashboard";
import FreeDealerDashboard from "../components/FreeDealer/FreeDealerDashboard";
import FreeDealerUserRequest from "../components/FreeDealer/FreeDealerUserRequest";
import FreeDealerInvoices from "../components/FreeDealer/FreeDealerInvoices";
import BusinessPartnerDashboard from "../components/BusinessPartnerLogin/BusinessPartnerDashboard";
import BusinessPartnerParkHistory from "../components/BusinessPartnerLogin/BusinessPartnerParkHistory";
import ViewParkHistory from "../components/ParkHistory/ViewParkHistory";
import NationalHolidays from "../components/NationalHolidays/NationalHolidays";
import ControllerAccounts from "../components/BusinessPartnerLogin/ControllerAccounts";
import FreeDealerDisputeRequests from "../components/FreeDealer/FreeDealerDisputeRequests";
import PaidDealerDisputeRequests from "../components/PaidDealer/PaidDealerDisputeRequests";
import DisputeRequest from "../components/DisputeRequest/DisputeRequest";
import ChildBusinessPArtnersParkHistory from "../components/ChildBusinessPartnerLogin/ChildBusinessPArtnersParkHistory";
import AdminProfile from "../components/Profile/AdminProfile";
import PaidDealerProfile from "../components/Profile/PaidDealerProfile";
import FreeDealerProfile from "../components/Profile/FreeDealerProfile";
import ChildBusinessPartnerProfile from "../components/Profile/ChildBusinessPartnerProfile";
import BusinessPartnerProfile from "../components/Profile/BusinessPartnerProfile";
import ForgotPassword from "../components/Authentication/ForgotPassword";
import VerifyOTP from "../components/Authentication/VerifyOTP";
import SetNewPassword from "../components/Authentication/SetNewPassword";
import DealerRequests from "../components/DealerRequests/DealerRequests";
import FreeDealerManualReacharge from "../components/FreeDealer/FreeDealerManualReacharge";
import PaidDealerManualRecharge from "../components/PaidDealer/PaidDealerManualRecharge";
import UserRechargesByDealer from "../components/UserRecharges(ByDelaer)/UserRechargesByDealer";
import UserRechargesToAdmin from "../components/UserRecharges(ToAdmin)/UserRechargesToAdmin";
import Reports from "../components/reports/Reports.js";

const Loading = (Component) => (props) =>
  (
    <Suspense fallback={<withLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Application = Loading(lazy(() => import("../components/Application")));
const Login = Loading(
  lazy(() => import("../components/Authentication/Login.js"))
);
const BusinessPartners = Loading(
  lazy(() => import("../components/BusinessPartners/BusinessPartners"))
);
const Users = Loading(lazy(() => import("../components/Users/Users")));
const City = Loading(lazy(() => import("../components/City/City")));
const DashboardPage = Loading(
  lazy(() => import("../components/Dashboard/Dashboard"))
);

const NotFoundPage = Loading(
  lazy(() => import("../components/Session/NotFound"))
);
const Area = Loading(lazy(() => import("../components/Area/Area")));
const AddParkingSlot = Loading(
  lazy(() => import("../components/Parking/AddParkingSlot"))
);
const Parking = Loading(lazy(() => import("../components/Parking/Parking")));

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const neosStorageString = localStorage.getItem("neos-storage");
const neosStorage = JSON.parse(neosStorageString);

const role = neosStorage && neosStorage.role;
const type = neosStorage && neosStorage.dealerType;

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <Application /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        element: (
          <>
            {role === "ADMIN" && <RedirectionWrapper to="/dashboard/" />}
            {role === "BUSINESS_PARTNER" && (
              <RedirectionWrapper to="/businesspartnerdashboard/" />
            )}
            {role === "DEALER" && type === "PAID" && (
              <RedirectionWrapper to="/paiddealerdashboard/" />
            )}
            {role === "DEALER" && type === "FREE" && (
              <RedirectionWrapper to="/freedealerdashboard/" />
            )}
            {role === "CHILD_BUSINESS_PARTNER" && (
              <RedirectionWrapper to="/childbusinesspartnerparkhistory/" />
            )}
          </>
        ),
        index: true,
      },
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/city",
        element: <City />,
      },
      {
        path: "/area",
        element: <Area />,
      },
      {
        path: "/businesspartners",
        element: <BusinessPartners />,
      },
      {
        path: "/parking",
        element: <Parking />,
      },
      {
        path: "/parkhistory",
        element: <ParkHistory />,
      },
      {
        path: "/parkhistory/:id",
        element: <ViewParkHistory />,
      },
      {
        path: "/parking/parkingSlot",
        element: <AddParkingSlot />,
      },
      {
        path: "/parking/:id",
        element: <ViewParkingSlots />,
      },
      {
        path: "/invoices",
        element: <Invoices />,
      },
      {
        path: "/users/:id",
        element: <Profile />,
      },
      {
        path: "/businesspartners/:id",
        element: <BusinessPartnersProfile />,
      },
      {
        path: "/city/:id",
        element: <CityProfile />,
      },
      {
        path: "/area/:id",
        element: <AreaProfile />,
      },
      {
        path: "/dealerrequests",
        element: <DealerRequests />,
      },
      // {
      //   path: "/reports",
      //   element: <Reports />,
      // },
      {
        path: "/userrechargebydealer",
        element: <UserRechargesByDealer />,
      },
      {
        path: "/userrechargefromapp",
        element: <UserRechargesToAdmin />,
      },
      {
        path: "/nationalholidays",
        element: <NationalHolidays />,
      },
      {
        path: "/disputerequest",
        element: <DisputeRequest />,
      },
      {
        path: "/rechargerequest",
        element: <RechargeRequest />,
      },
      {
        path: "/userrequest",
        element: <UserRequest />,
      },
      {
        path: "/paiddealerinvoice",
        element: <PaidDealerInvoices />,
      },
      {
        path: "/paiddealerdashboard",
        element: <PaidDealerDashboard />,
      },
      {
        path: "/paiddealerdisputerequests",
        element: <PaidDealerDisputeRequests />,
      },
      {
        path: "/paiddealermanualreacharge",
        element: <PaidDealerManualRecharge />,
      },
      {
        path: "/freedealerdashboard",
        element: <FreeDealerDashboard />,
      },
      {
        path: "/freedealeruserrequest",
        element: <FreeDealerUserRequest />,
      },
      {
        path: "/freedealerinvoice",
        element: <FreeDealerInvoices />,
      },
      {
        path: "/freedealerdisputerequests",
        element: <FreeDealerDisputeRequests />,
      },
      {
        path: "/freedealermanualreacharge",
        element: <FreeDealerManualReacharge />,
      },
      {
        path: "/businesspartnerdashboard",
        element: <BusinessPartnerDashboard />,
      },
      {
        path: "/controlleraccounts",
        element: <ControllerAccounts />,
      },
      {
        path: "/businesspartnerparkhistory",
        element: <BusinessPartnerParkHistory />,
      },
      {
        path: "/childbusinesspartnerparkhistory",
        element: <ChildBusinessPArtnersParkHistory />,
      },
      {
        path: "/adminprofile/:id",
        element: <AdminProfile />,
      },
      {
        path: "/paiddealerprofile/:id",
        element: <PaidDealerProfile />,
      },
      {
        path: "/freedealerprofile/:id",
        element: <FreeDealerProfile />,
      },
      {
        path: "/businesspartnerprofile/:id",
        element: <BusinessPartnerProfile />,
      },
      {
        path: "/childbusinesspartnerprofile/:id",
        element: <ChildBusinessPartnerProfile />,
      },
    ],
  },
  {
    path: "/forgotpasssword",
    element: <ForgotPassword />,
  },
  {
    path: "/verifyotp/:email",
    element: <VerifyOTP />,
  },
  {
    path: "/setnewpassword/:email/:token",
    element: <SetNewPassword />,
  },
  {
    path: "/login",
    element: !isLoggedIn ? (
      <Login />
    ) : (
      <>
        {role === "ADMIN" && <RedirectionWrapper to="/dashboard/" />}
        {role === "BUSINESS_PARTNER" && (
          <RedirectionWrapper to="/businesspartnerdashboard/" />
        )}
        {role === "DEALER" && type === "PAID" && (
          <RedirectionWrapper to="/paiddealerdashboard/" />
        )}
        {role === "DEALER" && type === "FREE" && (
          <RedirectionWrapper to="/freedealerdashboard/" />
        )}
        {role === "CHILD_BUSINESS_PARTNER" && (
          <RedirectionWrapper to="/childbusinesspartnerparkhistory/" />
        )}
      </>
    ),
  },
  { path: "*", element: <NotFoundPage /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
